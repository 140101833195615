
import {get,post} from "@/axios";



export function getList(data) {
  return get('/api/email_list/blacklist',data);

}

export function saveList(data) {
  return post('/api/email_list/set_words',data);

}



