<template>
  <div>
    <!-- <div class="mainContent" style="display: flex;    align-items: center;justify-content: center;flex-wrap: wrap;">
      <div style="display: flex;align-items: center;">
        <span style="white-space: nowrap;"> 关键词：</span>
        <a-input  v-model="blacklist" style="width: 100%" placeholder="" />
      </div>
      <a-button type="primary" @click="add" style="margin-left:16px">
        添加
      </a-button>
    </div> -->

    <div class="mainContent" style="display: flex;    align-items: center;justify-content: space-between;flex-wrap: wrap;">

      <div style="display: flex;    align-items: center;justify-content: center;flex-wrap: wrap;">
        <div style="display: flex;align-items: center;">
          <span style="white-space: nowrap;"> 关键词：</span>
          <a-textarea  v-model="blacklist" style="width: 100%" placeholder="" />
        </div>
        <a-button type="primary" @click="add" style="margin-left:16px">
          添加
        </a-button>
        <a-button type="primary" @click="save" style="margin-left:16px">
          保存
        </a-button>
      </div>

    </div>

    <!-- <div class="mainContent" style="display: flex;    align-items: center;justify-content: center;flex-wrap: wrap;">
      <a-form-model-item label="关键词黑名单" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="send_email" style="margin-bottom:0;margin-right:16px;width:100%;max-width:1000px">
        <a-select mode="tags" v-model="data" style="width: 100%" placeholder="" @change="handleChange" >
          <a-select-option v-for="i in data" :key="i" :value="i">
            {{i}}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-button type="primary" @click="save">
        保存
      </a-button>
    </div> -->
    <div class="mainContent" style="display: flex;    align-items: center;justify-content: space-between;flex-wrap: wrap;">
      <div class="tableHeader" style="display: flex;justify-content: space-between;align-items: center;">
        <div class="title">黑名单列表</div>

      </div>
    </div>
    <div class="mainContent">
      <div class="lyqCheckbox">
        <a-checkbox-group
          v-model="data"
          name="checkboxgroup"
          :options="checkboxGroupData"

        />
      </div>

    </div>



  </div>
</template>
<script>
import {getList,saveList} from "@/axios/blacklist.js"

import {getCookie} from '@/static/js/cookie';
import {myMixin} from "@/static/js/mixin.js"


export default {
  filters: {

    // roleFilter(status) {
    //   const statusMap = {
    //     'super': '超级管理员',
    //     'normal': '供应商',
    //     'agent':'代理'
    //   }
    //   return statusMap[status]
    // },
  },
  mixins: [myMixin],
  data() {

    return {

      labelcol:{//搜索框尺寸
          md:{span: 4},
          sm:{span: 4}
      },
      wrappercol:{
          md:{span: 20},
          sm:{span: 20}
      },

      data:[],//绑定的值
      dataList:[],//从接口获取的数据留存一份数组用来比对
      checkboxGroupData:[],//列表展开的数据
      blacklist:'',//输入框添加的内容
    }

  },
  mounted() {


    this.fetch();
  },
  methods:{

      async fetch (params = {}) {
        let data=await getList()
        this.data = [...data.data.words];
        this.dataList = [...data.data.words];
        this.checkboxGroupData=this.data.map((item)=>{
          return {
            label: item, value: item
          }
        })
        console.log(this.checkboxGroupData)
      },

      add(){
        
        let addData=this.blacklist.split('\n')
        addData=Array.from(new Set(addData))
        addData=addData.filter((item)=>{
          if(item!=''&& !(this.dataList.includes(item))){
            return item
          }
        })

        addData.map((item,index)=>{
          this.dataList.push(item)
          this.checkboxGroupData.push({label:item,value:item});
          this.data.push(item);
        })
        this.blacklist='';
        addData='';
      },
      //保存
      handleChange(data){
        // this.data=data

      },
      async save(){
        console.log(this.data)
        let resule=await saveList({words:this.data.join('|||')});
          if(resule.code==200){
            this.fetch()
            this.$message.success(resule.msg);
          }else{
            this.$message.error(resule.msg);
          }
      },

    
     
    }
};
</script>
<style lang="scss" scoped>
@import "@/static/css/index.scss";
.ant-table td { white-space: nowrap; }

::v-deep .ant-table-bordered .ant-table-thead > tr > th{    word-break: inherit;}
::v-deep .ant-modal-body {

    max-height: 600px;
    overflow-x: auto;
}
::v-deep .ant-select-selection--multiple .ant-select-selection__choice{margin: 4px 4px 0px 0px;}
::v-deep .lyqCheckbox .ant-checkbox-group-item{
  margin-right: 16px;
  width: 250px;
  padding: 5px 10px;
  border-radius:2px;
  transition: all 0.3s;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background:#89c1f11a;
}
::v-deep .lyqCheckbox .ant-checkbox-group-item:hover{    background: #a5d1e654;}
</style>
